var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$i18n.messages.ReportBtn}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12 my-2"}},[_c('b-table',{attrs:{"striped":"","responsive":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
              width:
                field.key === 'Actions' ||
                field.key === 'Sentencia' ||
                field.key === 'Nombre'
                  ? '500px'
                  : '200px',
            })})})}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"btn-action",staticStyle:{"min-width":"100px"},on:{"click":function($event){return _vm.generatePdf(data.item)}}},[_vm._v(" Generar PDF ")]),(data.item.Accion === 0)?_c('b-button',{staticClass:"btn-action",staticStyle:{"min-width":"100px","margin-left":"20px"},on:{"click":function($event){return _vm.openReport(data.item)}}},[_vm._v(" Ver informe ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalItems,"per-page":_vm.perPage,"align":"center","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }